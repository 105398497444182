const __pages_import_0__ = () => import("/src/pages/events/main/scanner.vue");
const __pages_import_1__ = () => import("/src/pages/success/index.vue");
const __pages_import_2__ = () => import("/src/pages/link/index.vue");
const __pages_import_3__ = () => import("/src/pages/error/index.vue");
const __pages_import_4__ = () => import("/src/pages/auth/index.vue");
import __pages_import_5__ from "/src/pages/index.vue";
const __pages_import_6__ = () => import("/src/pages/events/create/[uuid].vue");

const routes = [{"name":"events-main-scanner","path":"/events/main/scanner","component":__pages_import_0__,"props":true},{"name":"success","path":"/success","component":__pages_import_1__,"props":true},{"name":"link","path":"/link","component":__pages_import_2__,"props":true},{"name":"error","path":"/error","component":__pages_import_3__,"props":true},{"name":"auth","path":"/auth","component":__pages_import_4__,"props":true},{"name":"index","path":"/","component":__pages_import_5__,"props":true},{"name":"events-create-uuid","path":"/events/create/:uuid","component":__pages_import_6__,"props":true}];

export default routes;