<template>
  <div class="header-toolbar">
    <!-- Back button -->
    <v-btn
      v-if="!hideBackButton"
      icon="mdi-arrow-left"
      @click="goBack()"
      aria-label="Go back"
      class="back-button"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn> 

    <!-- Wrapper to center logo and title -->
    <div class="logo-title-wrapper">
      <!-- Logo -->
      <img v-if="hideBackButton" src="@/assets/logo.png" alt="Logo" class="header-logo" />

      <!-- Title -->
      <div class="title">{{ title }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title: string;
  backFunction?: () => void,
  hideBackButton: boolean
}>();

watch(() => props.title, () => {
  console.log("title",props.title);
  
});

function goBack() {
  if (props?.backFunction) {
    return props.backFunction();
  }
}
</script>

<style scoped>
.header-toolbar {
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 10px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-bottom: 1px solid #ddd;
  z-index: 1000;
  min-height: 93px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  justify-content: center; /* Center the logo-title-wrapper horizontally */
}

.header-toolbar .back-button {
  position: absolute;
  left: 20px;
}

.logo-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content inside this wrapper */
  flex: 1; /* Take up available space */
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10px; /* Space between the logo and title */
}

.header-logo {
  height: 40px; /* Adjust height as needed */
}
</style>