<template>
  <HeaderToolbar title="Erro" :hide-back-button="true" />
  <div class="not-found-container">
    <img src="@/assets/transparent_banner.png" alt="Banner Logo" class="banner-logo">
    <div class="content">
      <h1>404 - Página Não Encontrada</h1>
      <p>A página que você está procurando pode ter sido removida, alterada ou está temporariamente indisponível.</p>
      <v-btn @click="goHome" color="primary" class="home-button">
        Voltar para a Página Principal
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    goHome() {
      this.$router.push('/events/main/scanner');
    }
  }
}
</script>

<style scoped>
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  color: #333;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  padding: 20px;
}

.banner-logo {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-bottom: 30px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.banner-logo:hover {
  opacity: 1;
}

.content {
  max-width: 600px;
}

h1 {
  font-size: 3rem;
  color: #e53935;
  margin-bottom: 20px;
}

p {
  font-size: 1.125rem;
  color: #555;
  margin-bottom: 30px;
}

.home-button {
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 500;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
  height: 56px;
}

.home-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.home-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}
</style>