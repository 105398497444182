import { createRouter, createWebHistory } from 'vue-router';

import { setupLayouts } from 'virtual:generated-layouts'

import generatedRoutes from 'virtual:generated-pages';

import NotFound from '../components/NotFound.vue';

const routes = generatedRoutes.map(route => {
  // Paths that should not have authRequired or menu
  const excludedPaths = ['/auth', '/events/create', '/events/main/scanner', '/success', '/error'];

  // Check if the route's path starts with any of the excluded paths
  const isExcluded = excludedPaths.some(excludedPath => route.path.startsWith(excludedPath));

  // If the route is not excluded, set meta for auth and menu
  if (!isExcluded) {
    route.meta = {
      authRequired: true,
      menu: true
    };
  }

  return route;
});

const enhancedRoutes = setupLayouts(routes);
enhancedRoutes.push({ path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound });

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: enhancedRoutes
})

router.beforeEach(async (to, _from, next) => {  
  const isRouteAuthRequired = to.matched.some(route => route.meta.authRequired);
  const isAuthRoute = to.path.startsWith('/auth');
  const isAuthenticated = localStorage.getItem('token');
  const isPublicRoute = to.path.startsWith('/events/create');
  if(isPublicRoute) {
    next();
    return;
  }

  if (isAuthRoute && isAuthenticated) {
    next('/');
    return;
  }

  if (!isRouteAuthRequired) {
    next();
    return;
  }

  if (!isAuthenticated) {
    next('/auth');
    return;
  }

  next();
});

export default router