import axios from "axios"
import router from '../router'
import { IAuthResponse } from '../types/auth-response.type';
const baseURL = import.meta.env.VITE_API_URL

const clearStoredFields = () => {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('refreshToken');
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('expiresDate');
}

const getStoredField = (field: string) => window.localStorage.getItem(field);


function getAccount (): string {
  return window.location.hostname.split('.')[0]
}

function getControlsysUrl(){
  return `https://${getAccount()}.controlsys.app.br`;
}

const instance = axios.create({ baseURL })

const refreshToken = async () => {
  const token = getStoredField('refreshToken');
  if (!token) return null;

  try {
    const { data } : {data: IAuthResponse} = await axios.get(`${baseURL}/auth/refresh`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    const expiresDate = new Date(Date.now() + data.expiresIn * 1000);
    window.localStorage.setItem('token', data.token);
    window.localStorage.setItem('refreshToken', data.refreshToken);
    window.localStorage.setItem('user', JSON.stringify(data.user));
    window.localStorage.setItem('expiresDate', expiresDate.toString());

    return data.token;
  } catch (error) {
    clearStoredFields();
    router.push('/auth');
    throw new Error('Failed to refresh token');
  }
};

instance.interceptors.request.use(async (req) => {
  req.headers['x-app-version'] = import.meta.env.VITE_APP_VERSION;
  req.headers['x-account'] = getAccount();
  if(req.url?.includes('/links/public')) return req;
  if(req.url?.includes('/events')) return req;
  if(req.url?.includes('/files')){
    req.headers['Content-Type'] = 'multipart/form-data';
    return req;
  }

  if (req.url?.includes('/auth')) return req;
  let token = getStoredField('token');
  if (!token) {
    console.error('Authentication token not found');
    clearStoredFields();
    router.push('/auth');
    throw new Error('Authentication token not found');
  }
  const expiresDateStr = getStoredField('expiresDate');
  const expiresDate = expiresDateStr ? new Date(expiresDateStr) : new Date();
  if (new Date() < expiresDate) {
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  }
  token = await refreshToken();
  if (!token) {
    clearStoredFields();
    router.push('/auth');
    throw new Error('Failed to refresh token');
  }

  req.headers.Authorization = `Bearer ${token}`;
  return req;
}, error => Promise.reject(error));

instance.interceptors.response.use(
  response => response.data,
  async (error) => {
    const status = error.response?.status;
    const errorCode = error.response?.data?.code;

    console.log(error);

    if (status === 401 || errorCode === 401) {
      // clearStoredFields();
      // await router.push('/auth');
    }
    return Promise.reject(error.response.data);
  }
);

export default {
  $http: instance,
  $account: getAccount,
  $controlsysUrl: getControlsysUrl
}
