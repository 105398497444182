<template>
  <v-container fluid class="main-container">
    <HeaderToolbar title="Controlsys Work" :hide-back-button="true" />
    <v-row>
      <v-col cols="12" sm="6" md="3" class="pa-2" v-for="(button, index) in buttons" :key="index">
        <v-btn
          block
          :color="button.color"
          dark
          class="square-button animate-button"
          @click="button.action"
        >
          <v-icon left>
            {{ button.icon }}
          </v-icon>
          {{ button.label }}
        </v-btn>
      </v-col>
    </v-row>
    
    <v-dialog v-model="dialog" persistent max-width="300px">
      <v-card>
        <v-card-title class="text-h5">Não disponível</v-card-title>
        <v-card-text>Esta funcionalidade está em desenvolvimento!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="closeModal">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { ref, inject } from 'vue';
import HeaderToolbar from '../components/HeaderToolbar.vue';

const $account = inject('$account') as Function;
const url = ref(`https://${$account()}.controlsys.app.br`);
const router = useRouter();
const dialog = ref(false);

const openModal = () => {
  dialog.value = true;
}
const closeModal = () => {
  dialog.value = false;
}
const navigate = (path: string, isExternal = false) => {
  if (isExternal) {
    window.open(path, '_blank');
  } else {
    router.push(path);
  }
}

const buttons = [
  { label: 'Links', icon: 'mdi-link', color: 'blue', action: () => navigate('/link') },
  { label: 'Controlsys App', icon: 'mdi-forwardburger', color: 'blue', action: () => navigate(url.value, true) },
  { label: 'Manager', icon: 'mdi-cellphone', color: 'blue', action: openModal },
  { label: 'Dashboard', icon: 'mdi-monitor-dashboard', color: 'blue', action: openModal }
];
</script>

<style scoped lang="scss">
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #FFFFFF;
}

.square-button {
  width: 100%;
  height: 150px;
  font-size: 1.5rem;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 600px) {
  .square-button {
    height: 200px;
    font-size: 2rem;
  }
}

.animate-button:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.v-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>